// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:91e72ad9-780e-444a-a4a2-c403477fe874",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_a50NYnmBn",
    "aws_user_pools_web_client_id": "5bsrhq3ropkt9a7th69lesl5ms",
    "oauth": {},
    "aws_user_files_s3_bucket": "sofomni-prod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "sfoomni-20191115162614-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dlrl388aq1f69.cloudfront.net",
    "aws_mobile_analytics_app_id": "854cd87c50254cd697614241c70e0ddc",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
